export const environment = {
  production: false,
  domain: 'localhost:4200',
  region: 'ap-northeast-1',
  api_base_url: 'https://app.apisec.cncon.jp/v1/',
  chime_url: 'https://app.apisec.cncon.jp/v1/chime/',
  userPoolIdForPharmacy: 'ap-northeast-1_loPQvpvyt',
  clientIdForPharmacy: '7sq9t32pm0k0ltldgr7l5n62cn',
  userPoolIdForPharmacist: 'ap-northeast-1_YQrZ2jaek',
  clientIdForPharmacist: '6lohp6j4ejjao6la1cufddge8f',
  title: '',
  windowTitle: 'Connect Online',
  firebase: {
    apiKey: 'AIzaSyA29VTv4hg3KE6Cx5XWq1CtZrL1-CyyOwQ',
    authDomain: 'connect-online-9d05e.firebaseapp.com',
    projectId: 'connect-online-9d05e',
    storageBucket: 'connect-online-9d05e.appspot.com',
    messagingSenderId: '187779671788',
    appId: '1:187779671788:web:46767bd7b62dec9ab027c9',
    measurementId: 'G-S9KEHNKL4V',
  },
};
